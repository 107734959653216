/* ##remfixer: 1080 */

.GameList {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.GameList .BlockBox li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.GameList .BlockBox li.last {
  border: none;
}

.content {
  width: 100%;
  overflow: hidden;
}